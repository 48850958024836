function ArrowDown(props: { fill: string; secondaryfill: any; title: string }) {
  const fill = props.fill || 'currentColor'
  const secondaryfill = props.secondaryfill || fill
  const title = props.title || 'arrow right'

  return (
    <svg
      height='100'
      width='100'
      viewBox='10 0 44 64'
      xmlns='http://www.w3.org/2000/svg'
      className='h-[50px] w-[35px] sm:h-[100px] sm:w-[70px] lg:h-[175px] lg:w-[100px]'
    >
      <title>{title}</title>
      <g
        strokeLinecap='square'
        strokeWidth='4'
        fill={secondaryfill}
        stroke={secondaryfill}
        strokeLinejoin='miter'
        strokeMiterlimit='10'
      >
        <polyline fill='none' points='50,43 32,61 14,43 ' stroke='#ffffff' />
        <line
          fill='none'
          stroke={fill}
          strokeLinecap='butt'
          x1='32'
          y1='-2'
          x2='32'
          y2='56'
        ></line>
        <polyline fill='none' points='50,38 32,56 14,38 '></polyline>
      </g>
    </svg>
  )
}

export default ArrowDown
