/* eslint-disable @typescript-eslint/no-explicit-any */
import { client } from './client'

export const Feature = async (loc: string) => {
  // console.log('query', loc)
  try {
    const result = await client.nodes.get({ path: loc, entryFields: "*" as any })
    // console.log(result?.entry)

    // const result = await client.entries.get(path?.entry?.sys.id as string)
    // console.log(result)
    return result.entry
  } catch (err) {
    console.log(err)
  }
}
