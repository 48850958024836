/* eslint-disable @typescript-eslint/no-explicit-any */
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useLocation } from '@remix-run/react'
import {
  dehydrate,
  QueryClient,
  useQuery
} from '@tanstack/react-query'

import { Feature } from '~/lib/contensis/pageQuery'

import { Navigation, Footer } from '@university-of-hull/component-library'

import { HeroDynamic } from '~/components/HeroDynamic/HeroDynamic'
import { MainBody } from '~/components/MainBody/MainBody'

export async function loader({ request }: LoaderFunctionArgs) {
  const queryClient = new QueryClient()
  const url = new URL(request.url)

  await queryClient.prefetchQuery({
    queryKey: ['alumniData'],
    queryFn: () =>
      Feature(url.pathname)
  })

  return json({ dehydratedState: dehydrate(queryClient) })
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const meta = data?.dehydratedState?.queries[0]?.state?.data as {
    sys: {
      id: string
      uri: string
      allUris: string[]
    }
    seoStandardFields: {
      title: string
      description: string
      socialImage: {
        asset: {
          sys: {
            uri: string
          }
        }
      }
    }
  }

  return [
    meta?.sys?.allUris.length > 1 &&
      ({
        rel: 'canonical',
        href: `${meta.sys?.uri}`
      } as any),
    { title: `${meta?.seoStandardFields?.title} | University of Hull` },
    { name: 'description', content: `${meta?.seoStandardFields?.description}` },
    { name: 'IDL:EntryId', content: `${meta?.sys?.id}` },
    { name: 'IDL:ProjectId', content: 'website' },
    { property: 'og:title', content: `${meta?.seoStandardFields?.title}` },
    { property: 'og:url', content: `https://www.hull.ac.uk${meta?.sys.uri}` },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:description',
      content: `${meta?.seoStandardFields?.description}`
    },
    {
      property: 'og:image',
      content: `https://www.hull.ac.uk${meta?.seoStandardFields?.socialImage?.asset?.sys?.uri}`
    },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@UniOfHull' },
    { name: 'twitter:title', content: `${meta?.seoStandardFields?.title}` },
    {
      name: 'twitter:description',
      content: `${meta?.seoStandardFields?.description}`
    },
    {
      name: 'twitter:image',
      content: `https://www.hull.ac.uk${meta?.seoStandardFields?.socialImage?.asset?.sys?.uri}`
    }
  ]
}

export default function Index() {
  const location = useLocation()
  const about = location.pathname

  const { data } = useQuery({
    queryKey: ['alumniData'],
    queryFn: () => Feature(about)
  })

  return (
    <>
      {data && <Navigation />}
      
      {data?.heroDynamic && <HeroDynamic content={data?.heroDynamic} />}

      {data?.mainBody && <MainBody body={data?.mainBody} lead={data?.contentLeadParagraph} />}

      {data && <Footer />}
    </>
  )
}
