/* eslint-disable @typescript-eslint/no-explicit-any */
import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'
import sanitizeHtml from 'sanitize-html'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white', 'text-blue-d'],
      chartreuse: ['bg-char', 'text-blue-d'],
      'ink blue': ['bg-blue-d', 'text-white'],
      'mid blue': ['bg-blue-m', 'text-white'],
      'light blue': ['bg-blue-l', 'text-blue-d'],
      green: ['bg-green', 'text-blue-d'],
      purple: ['bg-purple', 'text-blue-d'],
      pink: ['bg-pink', 'text-blue-d'],
      orange: ['bg-orange', 'text-blue-d']
    }
  }
})

export const HeroDynamic = ({ content }: any) => {
  const title = content?.title
    .replaceAll(/_([^_]+)_/gi, '<em>$1</em>')
    .replaceAll('\\', '<br/>')

  const background = content?.backgroundColour.entryTitle.toLowerCase()
  const bgOverlay =
    content?.backgroundOverlay &&
    (process.env.NODE_ENV === 'development'
      ? 'https://preview-uni-hull.cloud.contensis.com'
      : '') +
      content?.backgroundOverlay.asset.sys.uri +
      '?f=webp&q=30'
  const bgImage =
    content?.backgroundImage &&
    (process.env.NODE_ENV === 'development'
      ? 'https://preview-uni-hull.cloud.contensis.com'
      : '') +
      content?.backgroundImage.asset.sys.uri +
      '?f=webp&q=30'

  return (
    <header className='relative bg-blue-d'>
      <div
        style={{ backgroundImage: `url(${bgOverlay})` }}
        className='absolute bottom-0 left-0 z-[1] h-[50px] min-w-[100vw] translate-y-[25px] bg-cover bg-no-repeat opacity-50 sm:h-[70px] sm:translate-y-[35px] lg:h-[100px] lg:translate-y-[50px]'
      ></div>
      <div
        style={{ backgroundImage: `url(${bgImage})` }}
        className='absolute bottom-0 left-0 h-[100px] min-w-[100vw] bg-cover bg-no-repeat sm:h-[140px] lg:h-[200px]'
      ></div>

      <div className='container flex-col pt-[100px] sm:pt-[140px] lg:flex-row'>
        <div
          className={[
            twMerge(section({ background })),
            'relative order-2 mx-[15px] basis-[40%] p-sm before:absolute before:-top-[20px] before:left-0 before:z-[1] before:block before:h-[20px] before:w-[calc(50%-10px)] before:bg-char after:absolute after:-top-[20px] after:right-0 after:z-[1] after:block after:h-[20px] after:w-[calc(50%-10px)] after:bg-char sm:mx-[25px] sm:p-md sm:before:-top-[30px] sm:before:h-[30px] sm:before:w-[calc(50%-15px)] sm:after:-top-[30px] sm:after:h-[30px] sm:after:w-[calc(50%-15px)] lg:order-1 lg:mx-0 lg:my-[50px] lg:p-lg lg:before:-right-[30px] lg:before:left-auto lg:before:top-0 lg:before:h-[calc(50%-15px)] lg:before:w-[30px] lg:after:-right-[30px] lg:after:bottom-0 lg:after:top-auto lg:after:h-[calc(50%-15px)] lg:after:w-[30px]'
          ].join(' ')}
        >
          <p className='tag'>{content?.tag}</p>
          <h1
            className='font-serif text-blue-m'
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(title, {
                allowedTags: ['em', 'br']
              })
            }}
          ></h1>

          <p className='border-l-[10px] border-solid border-blue-m pl-[20px] lg:pl-[30px]'>
            {content?.description}
          </p>
        </div>

        <div className='relative order-1 basis-[60%] before:absolute before:bottom-[20px] before:left-[calc(50%-10px)] before:z-[1] before:block before:h-[20px] before:w-[20px] before:bg-char sm:before:bottom-[30px] sm:before:left-[calc(50%-15px)] sm:before:h-[30px] sm:before:w-[30px] lg:order-2 lg:before:left-auto lg:before:right-[30px] lg:before:top-[50px] lg:before:h-[calc(50%-65px)] lg:after:absolute lg:after:bottom-[50px] lg:after:right-[30px] lg:after:z-[1] lg:after:block lg:after:h-[calc(50%-65px)] lg:after:w-[30px] lg:after:bg-char'>
          <div className='hidden before:absolute before:left-[30px] before:top-[calc(50%-15px)] before:h-[30px] before:w-[30px] before:bg-char after:absolute after:right-[60px] after:top-[calc(50%-15px)] after:h-[30px] after:w-[30px] after:bg-char lg:block'></div>
          <img
            className='aspect-[4/3] h-full w-full object-cover'
            src={
              content?.image &&
              (process.env.NODE_ENV === 'development'
                ? 'https://preview-uni-hull.cloud.contensis.com'
                : '') +
                content?.image?.asset?.sys.uri +
                (content?.image?.asset?.sys.uri.toString().includes('?')
                  ? '&'
                  : '?') +
                'f=webp&q=50'
            }
            alt={content?.image.altText}
            width={936}
            height={700}
          ></img>
        </div>
      </div>
    </header>
  )
}
