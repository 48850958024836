/* eslint-disable @typescript-eslint/no-explicit-any */
import sanitizeHtml from 'sanitize-html'
import { cva } from 'cva'

const borderColour = cva('max-w-[1200px] w-full m-auto [&>*]:text-h5 sm:[&>*]:text-h3 border-l-[15px] pl-[30px] relative before:absolute before:w-[30px] before:h-[15px] before:content before:-left-[15px] before:top-1/2 before:translate-y-1/2 before:bg-gradient-to-r before:from-white before:from-50% before:to-50% space-y-5', {
  variants: {
    background: {
      chartreuse: ['border-char', 'before:to-char'],
      'ink blue': ['border-blue-d', 'before:to-blue-d'],
      'mid blue': ['border-blue-m', 'before:to-blue-m'],
      'light blue': ['border-blue-l', 'before:to-blue-l'],
      green: ['border-green', 'before:to-green'],
      purple: ['border-purple', 'before:to-purple'],
      pink: ['border-pink', 'before:to-pink'],
      orange: ['border-orange', 'before:to-orange']
    }
  },
  defaultVariants: {
    background: "light blue"
  }
})

type Lead = {
  text: string
  border: {
    entryTitle: string
  }
}

type bground = "chartreuse" | "ink blue" | "mid blue" | "light blue" | "green" | "purple" | "pink" | "orange" | null | undefined

export const LeadParagraph = ({ text, border }: Lead) => {

  const background = border && border?.entryTitle.toLowerCase() as bground

  return (
    <section>
      <div className='container flex-col lg:flex-row'>
        <div
          className={borderColour({ background })}
          dangerouslySetInnerHTML={{
                __html: sanitizeHtml(text, {
                  allowedTags: [
                    'em',
                    'strong',
                    'h2',
                    'h3',
                    'h4',
                    'a',
                    'p',
                    'br'
                  ]
                })
              }}></div>
      </div>
    </section>
  )
}
