/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/UI/accordion"
import sanitizeHtml from 'sanitize-html'
import { cva } from 'cva'

const accordionWidth = cva('w-full m-auto space-y-6', {
  variants: {
    width: {
      small: 'max-w-[700px]',
      medium: 'max-w-[960px]',
      large: 'max-w-[1200px]'
    }
  },
  defaultVariants: {
    width: "medium"
  }
})

const accordionColour = cva('px-4', {
  variants: {
    itemColour: {
      'dark blue': 'bg-blue-d text-white',
      grey: 'bg-blue-d-10 text-blue-d'
    }
  },
  defaultVariants: {
    itemColour: "dark blue"
  }
})


type Acc = {
  content: {
    title: string
    description: string
    width: any
    itemColour: any
    nestedAccordionItem: {
      header: string
      content: string
    }[]
  }
}

type Width = "small" | "medium" | "large" | undefined | null
type ItemColour = "dark blue" | "grey" | undefined | null

export const GeneralAccordion = ({ content }: Acc) => {
  const width = content?.width ? content?.width.toLowerCase() as Width : "medium"
  const itemColour = content?.itemColour ? content?.itemColour.toLowerCase() as ItemColour : "dark blue"

  return (
    <section>
      <div className='container flex-col lg:flex-row'>
        <div className={accordionWidth({ width })}>
          {content?.title && <h2>{content?.title}</h2>}
          {content?.description && <div
            className='[&_ul]:list-disc [&_ul]:pl-4'
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content?.description, {
                allowedTags: ['em', 'strong', 'h2', 'h3', 'h4', 'a', 'p', 'br'],
              })
            }}
          ></div>}
          
          <Accordion type="single" collapsible className="w-full flex flex-col gap-4">
            {
              content?.nestedAccordionItem.map((a: any, i: Index) => (
                <AccordionItem key={i} value={`item-${i}`} className={accordionColour({ itemColour })}>
                  <AccordionTrigger>{a?.header}</AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-5" dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(a?.content, {
                        allowedTags: ['em', 'strong', 'h2', 'h3', 'h4', 'a', 'p', 'br', 'ul', 'li'],
                        allowedAttributes: {
                          '*': ['*']
                        },
                        transformTags: {
                          'ul': sanitizeHtml.simpleTransform('ul', {class: 'text-tag font-[450] md:text-base list-disc pl-4 space-y-1'}),
                          'a': sanitizeHtml.simpleTransform('a', {class: 'text-current cursor-pointer'}),
                        }
                      })
                    }}></div>
                  </AccordionContent>
                </AccordionItem>
              ))
            }
          </Accordion>

        </div>
      </div>
    </section>
  )
}
