/* eslint-disable @typescript-eslint/no-explicit-any */
import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'
import sanitizeHtml from 'sanitize-html'
import Button from '../UI/Button/Button'

const section = cva('section', {
  variants: {
    background: {
      white: ['bg-white', 'text-blue-d'],
      chartreuse: ['bg-char', 'text-blue-d'],
      'ink blue': ['bg-blue-d', 'text-white'],
      'mid blue': ['bg-blue-m', 'text-white'],
      'light blue': ['bg-blue-l', 'text-blue-d'],
      green: ['bg-green', 'text-blue-d'],
      purple: ['bg-purple', 'text-blue-d'],
      pink: ['bg-pink', 'text-blue-d'],
      orange: ['bg-orange', 'text-blue-d']
    }
  }
})

const HighlightDHImgContent = ({ content }: any) => {
  const background = content?.backgroundColour.entryTitle.toLowerCase()

  return (
    <section>
      <div className='container flex-col lg:flex-row'>
        <div className='relative -left-[20px] z-[1] basis-[50%] before:absolute before:left-[20px] before:top-[40px] before:z-[1] before:block before:h-[calc(50%-47.5px)] before:w-[15px] before:bg-char after:absolute after:bottom-[40px] after:left-[20px] after:z-[1] after:block after:h-[calc(50%-47.5px)] after:w-[15px] after:bg-char sm:-left-[50px] sm:mr-[70px] sm:before:left-[30px] sm:before:top-[60px] sm:before:h-[calc(50%-70px)] sm:before:w-[20px] sm:after:bottom-[60px] sm:after:left-[30px] sm:after:h-[calc(50%-70px)] sm:after:w-[20px] lg:left-0 lg:mr-0 lg:before:left-[30px] lg:before:top-[100px] lg:before:h-[calc(50%-115px)] lg:before:w-[30px] lg:after:bottom-[100px] lg:after:left-[30px] lg:after:h-[calc(50%-115px)] lg:after:w-[30px]'>
          <div className='before:absolute before:left-[35px] before:top-[calc(50%-7.5px)] before:h-[15px] before:w-[15px] before:bg-char sm:before:left-[50px] sm:before:top-[calc(50%-10px)] sm:before:h-[20px] sm:before:w-[20px] lg:before:left-[60px] lg:before:top-[calc(50%-15px)] lg:before:h-[30px] lg:before:w-[30px]'></div>
          <img
            className='aspect-square h-full w-full object-cover'
            loading='lazy'
            src={
              (process.env.NODE_ENV === 'development'
                ? 'https://preview-uni-hull.cloud.contensis.com'
                : '') +
              content?.image?.asset?.sys.uri +
              (content?.image?.asset?.sys.uri.includes('?') ? '&' : '?') +
              'f=webp'
            }
            alt={content?.image.altText}
            width={775}
            height={750}
          ></img>
        </div>

        <div
          className={[
            twMerge(section({ background })),
            'relative -mr-[20px] -mt-[50px] flex basis-[50%] flex-col justify-center p-sm pt-[80px] sm:-mr-[50px] sm:-mt-[70px] sm:ml-[70px] sm:p-md sm:pt-[110px] lg:my-[50px] lg:ml-0 lg:mr-[30px] lg:p-lg lg:before:absolute lg:before:-right-[30px] lg:before:top-[calc(50%-15px)] lg:before:z-[1] lg:before:block lg:before:h-[30px] lg:before:w-[30px] lg:before:bg-char lg:after:absolute lg:after:-right-0 lg:after:top-[calc(50%-15px)] lg:after:z-[1] lg:after:block lg:after:h-[30px] lg:after:w-[30px] lg:after:bg-white'
          ].join(' ')}
        >
          <p className='tag'>{content?.tag}</p>
          <h2>{content?.title}</h2>

          <div
            className='mb-[10px] lg:mb-[20px] [&_p]:mb-[20px]'
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content?.content, {
                allowedTags: ['em', 'strong', 'h3', 'h4', 'a', 'p', 'br']
              })
            }}
          ></div>

          {content?.uiPrimaryButtons.map((button: buttonType, index: Index) => (
            <Button
              key={index}
              btn={button as any}
              colour={button?.colours.toLowerCase()}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default HighlightDHImgContent
