/* eslint-disable @typescript-eslint/no-explicit-any */
import sanitizeHtml from 'sanitize-html'
import ArrowDown from './Icon/arrow-down'

const ContentHalves = ({ content }: any) => {
  return (
    <section>
      <div className={`
          container flex-col-reverse gap-[50px] sm:gap-[100px] lg:flex-row lg:gap-20
          ${content.find((e: { showArrow: boolean }) => e.showArrow  === true) ? 'pb-0 -mb-[7px] pt-[25px] sm:pt-[50px] sm:-mb-[20px] md:pt-[100px] lg:-mb-[45px]' : ''}
        `}>
        {content?.map((item: any, index: Index) => (
          <div
            key={index}
            className='flex flex-col lg:basis-[50%] lg:first:items-end lg:first:text-right'
          >
            <p className='tag'>{item?.tag}</p>

            <div
              className='space-y-5'
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(item?.content, {
                  allowedTags: [
                    'em',
                    'strong',
                    'h2',
                    'h3',
                    'h4',
                    'a',
                    'p',
                    'br'
                  ]
                })
              }}
            ></div>

            {item?.showArrow && (
              <div className='z-[2] mt-[20px] shrink-0 sm:block lg:mt-[50px]'>
                <ArrowDown
                  fill={'#1D4CF2'}
                  secondaryfill={undefined}
                  title={''}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default ContentHalves
