/* eslint-disable @typescript-eslint/no-explicit-any */
import MediaImgContent from '../MediaImgContent/MediaImgContent'
import ContentHalves from '../ContentHalves/ContentHalves'
import HighlightDHImgContent from '../HighlightDHImgContent/HighlightDHImgContent'
import { GeneralAccordion } from '../GeneralAccordion/GeneralAccordion'
import { LeadParagraph } from '../LeadParagraph/LeadParagraph'

export const MainBody = ({ body, lead }: any) => {
  return (
    <main id='main-content' className='min-h-screen'>
      {lead?.content && <LeadParagraph text={lead?.content} border={lead?.borderColour} />}

      {body?.map((comp: any, index: Index) => {
        switch (comp?.type) {
          case 'mediaImageWcontent':
            return (
              <MediaImgContent content={comp?.value} key={`comp-${index}`} />
            )
          case 'contentHalves':
            return <ContentHalves content={comp?.value} key={`comp-${index}`} />
          case 'highlightDHImageWcontent':
            return (
              <HighlightDHImgContent
                content={comp?.value}
                key={`comp-${index}`}
              />
            )
          case 'contentAccordion': 
            return (
              <GeneralAccordion content={comp?.value} key={`comp-${index}`} />
            )
        }
      })}
    </main>
  )
}
