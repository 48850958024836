/* eslint-disable @typescript-eslint/no-explicit-any */
import sanitizeHtml from 'sanitize-html'

const MediaImgContent = ({ content }: any) => {
  return (
    <section>
      <div
        className={`container flex-col items-center gap-[50px] pb-[25px] sm:gap-20 sm:pb-[50px] md:flex-row md:pb-[100px] ${content?.leftRight ? 'md:flex-row-reverse' : 'md:flex-row'}`}
      >
        <div className='basis-1/2' role='presentation'>
          <picture>
            <source
              srcSet={
                (process.env.NODE_ENV === 'development'
                  ? 'https://preview-uni-hull.cloud.contensis.com'
                  : '') +
                content?.image?.asset?.sys.uri +
                (content?.image?.asset?.sys.uri.includes('?') ? '&' : '?') +
                'f=webp'
              }
              type='image/webp'
            />
            <img
              className='max-w-auto mx-auto h-[275px] sm:h-[400px] md:h-auto'
              loading='lazy'
              src={
                (process.env.NODE_ENV === 'development'
                  ? 'https://preview-uni-hull.cloud.contensis.com'
                  : '') +
                content?.image?.asset?.sys.uri +
                (content?.image?.asset?.sys.uri.includes('?') ? '&' : '?') +
                'f=webp'
              }
              alt={content?.image?.altText}
              width={730}
              height={590}
            />
          </picture>
        </div>

        <div className='basis-1/2'>
          <p className='tag'>{content?.tag}</p>
          <div
            className='space-y-5'
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content?.content, {
                allowedTags: [
                  'h2',
                  'h3',
                  'h4',
                  'h5',
                  'p',
                  'a',
                  'strong',
                  'i',
                  'ul',
                  'ol',
                  'li',
                  'em',
                  'br'
                ]
              })
            }}
          ></div>
        </div>
      </div>
    </section>
  )
}

export default MediaImgContent
